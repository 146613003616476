.customer-table-card {
  border-radius: 1rem;
  max-height: 1200px;
  min-height: 600px;
}
.customer-table td:first-child {
  border-left-style: solid;
  border-top-left-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem;
}
.customer-table td:last-child {
  border-right-style: solid;
  border-bottom-right-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
}

.customer-table {
  border: #ffffff !important;
}

.data-color {
  color: #363636 !important;
}

.table-data {
  font-size: 12px;
  padding-top: 1rem !important;
}

.table-dropdown {
  font-size: 12px;
  padding-top: 0.5rem !important;
  margin-right: 0px !important;
}

.table-edit {
  font-size: 12px;
  padding-top: 0.6rem !important;
}

.table-edit .card {
  border-radius: 2.5rem;
  align-items: center;
  width: 4.2rem;
  background-color: #3e66cd;
  color: #eff3fb;
}

.add-position {
  position: fixed;
  right: 0;
  margin-right: 10px;
  /* bottom: 25px; */
  z-index: 2;
}

.add-position.mobile-view {
  top: 0px;
  margin-top: 7rem;
}

.add-position.lap-view {
  bottom: 30px;
}

.customer-add {
  font-size: 12px;
  padding-top: 0.6rem !important;
}

.customer-add .card {
  border-radius: 2.5rem;
  align-items: center;
  width: 15rem;
  font-size: 15px;
  background-color: #3e66cd;
  color: #eff3fb;
}

.table-dropdown .status-dropdown {
  border-radius: 0.8rem;
  width: 12rem !important;
  background-color: #f9f9f9;
  box-shadow: 1px 1px 1px #737373;
  color: #000000;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  font-weight: 500;
  font-size: 11px;
}
.table-dropdown .status-dropdown .menu {
  border-radius: 1rem;
  font-family: "Poppins", sans-serif !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.025em;
}

.payment-dropdown {
  border-radius: 0.8rem;
  width: 8rem !important;
  color: #000000;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  font-weight: 500;
  font-size: 11px;
}

.table-dropdown .Pending {
  background-color: #fd4545;
}

.table-dropdown .Paid {
  background-color: #67ff42 !important;
}

.table-dropdown .Partially {
  background-color: #fff14b !important;
}

.payment-dropdown .menu {
  border-radius: 1rem;
  font-family: "Poppins", sans-serif !important;
  background-color: #f9f9f9 !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.025em;
}

.document-card {
  border-radius: 3rem;
}

.customer-profile-card {
  border-radius: 3rem;
  transition: all 0.1s;
  background-color: rgb(
    32,
    0,
    162
  ); /* For browsers that do not support gradients */
  background-image: linear-gradient(to left, rgb(4, 0, 87), rgb(60, 90, 222));
  color: #ffffff;
}

.customer-profile-img {
  width: 8rem;
  aspect-ratio: 1/1;
  border-radius: 5rem;
  object-fit: cover;
  background: #dfdfdf;
}

.customer-profile-name {
  font-size: 20px;
  font-weight: 600;
}

.customer-profile-data {
  font-size: 14px;
  font-weight: 400;
}

.profile-edit-card {
  right: 4rem;
}

.profile-convert-card {
  right: 8rem;
}

@media only screen and (max-width: 768px) {
  .profile-common {
    margin-left: 0.1rem;
  }
}

@media only screen and (min-width: 768px) {
  .profile-common {
    right: 1rem;
  }
}

.customer-icons {
  top: 0;
}

@media only screen and (max-width: 768px) {
  .customer-icons {
    margin-right: 0rem;
  }
}

/* .download{
    text-decoration: none;
    color: #ffffff!important;
} */

.session-datepicker .MuiInputBase-root {
  height: 3rem;
  /* background-color: red; */
}

.session-datepicker {
  padding-top: 4px !important;
}

.interest-dropdown {
  border-radius: 0.8rem;
  width: 22rem !important;
  height: 2.9rem !important;
  background-color: #f9f9f9;
  box-shadow: 1px 1.2px 1.2px #737373;
  color: #000000;
  /* padding-top: 1rem !important; */
  padding-left: 0.8rem;
  font-weight: 500;
  font-size: 14px;
}
.interest-dropdown .menu {
  border-radius: 1rem;
  padding-left: 2rem !important;
  font-family: "Poppins", sans-serif !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.025em;
}

.add-interest {
  box-shadow: 1px 2px 2px #737373 !important;
  border-radius: 2rem;
}

.nav-link {
  background-color: #ffffff !important;
  color: rgb(4, 0, 87) !important;
}

.nav-item .active {
  background-color: #1f2b97 !important;
  color: #ffffff !important;
}

.add-interest-btn {
  background-color: rgb(4, 0, 87) !important;
  color: #ffffff !important;
}

.table-card {
  max-height: 18.5rem;
  overflow-y: auto;
  overflow-x: auto;
}

.field-data-card {
  max-height: 18.5rem;
  overflow-y: auto;
  overflow-x: hidden;
}

.call-button {
  border-radius: 5rem;
  background-color: #25ab04;
  color: #ffffff;
}
.customer-profile-card > .text-center .btn:hover {
  /* color: rgb(244, 0, 0); */
  background-color: #51ed2a;
  text-decoration: none;
}

.call-onclick {
  padding: 0.35rem 0.35rem;
  border-radius: 5rem;
  background-color: #1d8c01;
  color: #ffffff;
}

.call-onclick:hover {
  /* color: rgb(244, 0, 0); */
  background-color: #0c0632;
  color: #ffffff;
  text-decoration: none;
}

.ext-onclick {
  padding: 0.35rem 0.35rem;
  border-radius: 5rem;
  background-color: #15258d;
  color: #ffffff;
}

.ext-onclick:hover {
  /* color: rgb(244, 0, 0); */
  background-color: #0c0632;
  color: #ffffff;
  text-decoration: none;
}

.loader {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #000;
  opacity: 0.3;
  margin: 0 5px;
  animation-name: fade;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

.dot-1 {
  animation-delay: 0s;
}

.dot-2 {
  animation-delay: 0.4s;
}

.dot-3 {
  animation-delay: 0.8s;
}

@keyframes fade {
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.3;
  }
}

@media (max-width: 1443px) {
  .topleft-133 {
    padding-left: 1.5rem !important;
  }
  .search-133 {
    margin-right: 300px;
  }
  .s-input-133 {
    width: 170px;
  }
}
