.message-container {
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}

.message-container-left {
  border-radius: 10px;
}

.message-container-right {
  border-radius: 10px;
}

.container-left-top {
  background-color: #3b3367;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.container-left-top .left-top-input {
  background-color: #6e6795;
  border: none;
  outline: none;
  border-radius: 10px;
}

.container-left-top .input-icon-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.container-left-top .input-icon-wrapper .search-icon {
  position: absolute;
  left: 10px;
  color: white; /* Change this color as needed */
}

.select-all-input {
  width: 20px;
  height: 20px;
}

.container-left-bottom .horizontal-line {
  background-color: #d2d2d2;
  height: 1.5px;
}

.container-left-bottom .user-list-bottom {
  height: 450px;
  overflow: scroll;
}

.container-left-top .tab-switch {
  border: 1px solid gainsboro;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.container-left-top .tab-button {
  color: whitesmoke;
  background-color: #3b3367;
  border-radius: 7px;
  border: none;
}

.container-left-top .active {
  background-color: #6e6795;
}

.user-list-bottom .box {
  height: 90px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  border-radius: 12px;
}

.box .box-content {
  width: 60%;
}

.box .box-text {
  font-weight: 700;
}

.box .lead-avatar {
  background-color: #ff8fd9;
  color: black;
}

.container-right-top {
  background-color: #3b3367;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.box.selected {
  background-color: #8f86c6; /* Change this to the desired background color */
  color: white;
}

/* .container-right-bottom {
} */

.container-right-bottom .single-lead {
  height: 460px;
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  background-color: #f8f8f8;
  position: relative;
}

.container-right-bottom .message-box {
  border-radius: 8px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  position: fixed;
  bottom: 3.5%;
  width: 48.4%;
  height: 70px;
}

.container-right-bottom .message-box .message-uploader {
  background-color: #f8f8f8;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.container-right-bottom .message-box .message-text {
  outline: none;
  border: none;
  background-color: #f8f8f8;
  width: 60%;
  height: 40px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.container-right-bottom .message-box .message-send {
  background-color: #3b3367;
  border-radius: 8px;
  height: 40px;
  width: 16%;
  cursor: pointer;
}

.container-right-bottom .message-box .message-send:hover {
  background-color: #594e97 !important;
}

.container-right-bottom .single-lead .chat-box {
  height: 370px;
  overflow: scroll;
}

.chat-box .message-date {
  background-color: #d3dfff;
  border-radius: 6px;
}

.chat-box .chat-message {
  background-color: #d9d9d9;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  width: 200px;
}

.chat-box .chat-image {
  width: 200px;
  height: 120px;
  border-radius: 10px;
}

.lead-last-message {
  height: 24px;
  width: 90%;
  overflow: hidden;
}

.lead-last-message-time {
  font-size: 11px;
}

.document-preview {
  display: flex;
  align-items: center;
  gap: 10px;
}

.document-preview .fs-2 {
  font-size: 2rem;
}

.document-preview a {
  text-decoration: none;
  color: blue;
}

.document-preview a:hover {
  text-decoration: underline;
}

.message-image-upload {
  border: 0.2rem dashed #c1c1c1;
}
