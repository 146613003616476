.table-row {
  background-color: #fff;
}


.table-row:hover {
    background-color: rgb(241, 241, 248);
}

.table-main-col{
    color: blue;
}

.table-main-col:hover {
    text-decoration: underline;
    cursor: pointer;
}

.user-details {
    line-height: 2;
}

.users-list {
    font-size: 0.9rem;
}

.gifted {
    background-color: #c2f8c2;
}

.not-gifted {
    background-color: #f8c2c2;
}

.given:hover {
    border:2px solid#c2f8c2;
    background-color: #c2f8c2;
}

.not-given:hover {
    border:2px solid #f8c2c2;
    background-color:  #fadddd;
}
