.qr-box {
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  height: 500px;
}

.qr-image {
  background: transparent; /* Ensure the image background is transparent */
  padding: 0.5%;
  border-radius: 7px;
  background-color: #3b3367;
}

.instructions-table {
  /* border-spacing: 10px 10px; */
}

.link-btn {
  border: 1px solid #3b3367;
}
