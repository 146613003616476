.quotation-table table tr:first-child:hover {
    background-color: none;
}

.quotation-table table input {
    border-top: 0;
    border-right: 0;
    border-left: 0;
    border-radius: 0px;
}

.quotation-table table input[disabled] {
    background-color: white !important; 
    border: 0;
}

.quotation-table table input:focus {
    box-shadow: none;
    border-color: #afafaf;
}

.quotation-item .search, .customer-details .search{
    width: 100% !important; 
    background-color: white !important; 
}

.print-page .quotation-company-details, .print-page .quotation-customer-details{
    font-family: Arial, Helvetica, sans-serif;
    /* font-weight: 600; */
    font-size: 0.9rem;
    line-height: 1.3;
}

.print-page{
    font-size: 0.8rem;
    width: 210mm;
    height: 297mm;
    line-height: 1.7;
    /* background-color: aqua; */
}

#quotation-preview table{
    font-size: 0.8rem;    
}

.modal-print{
    width: auto;
    height: auto;
}

.print-page .company-logo{
    width: 55mm;
}

.print-page .vox-logo{
    width: 25mm;
}